<template>
  <div>
    <section class="system-filter" ref="systemContainer" :style="{ 'height': containerHeight }">
      <div class="system-filter__wrapper">
        <MediaCart
          v-for="system in systems"
          :source="system"
          :key="system.id"
          :vertical-grid="true"
          :active="currentSystemId === system.id"
          @selectItem="selectSystem($event)"
          @triggerModal="modalEvent($event)"
        />
      </div>
    </section>
    <algorithm-switcher
      v-if="canShowSwitcher"
      :selectedAlgorithm="selectedAlgorithm"
      @setAlgorithm="setAlgorithm($event)"
    />
  </div>
</template>

<script>
import MediaCart from '@/components/dump/MediaCart'
import { mapActions, mapGetters } from 'vuex'
import { initialVisibleItems, setContainerHeight } from '@/utils/utils'
import AlgorithmSwitcher from '@/components/dump/AlgorithmSwitcher.vue'
import { CalcTypes } from '@/utils/types'

export default {
  name: 'SystemFilter',
  props: ['sector'],
  components: { AlgorithmSwitcher, MediaCart },
  data: () => ({
    containerHeight: 'none',
    currentSystemId: null
  }),
  watch: {
    sector() {
      this.setScrollHeight()
      this.currentSystemId = this.id
    },
    system() {
      this.currentSystemId = this.id
      this.reset()
    },
    systems() {
      this.setScrollHeight()
    },
    selectedAlgorithm(newestValue, oldValue) {
      if (newestValue !== oldValue) {
        this.reset()
      }
    }
  },
  computed: {
    ...mapGetters({
      getConstructionTypes: 'getConstructionTypes'
    }),
    sectorIndex() {
      return this.$store.state.sectors.findIndex((sector) => sector.id === this.sector.id)
    },
    systems() {
      const constructionType = this.getConstructionTypes
        .find((type) => type.constructionType === this.sector.constructionType.type)

      return constructionType
        ? constructionType.systems?.filter((system) => !system.hiddenFor.includes(this.location.id))
        : []
    },
    system() {
      return this.sector.system
    },
    location() {
      return this.sector.constructionType.location
    },
    id() {
      return this.sector.filling?.id ?? this.sector.system?.id
    },
    selectedAlgorithm() {
      return this.sector.algorithm
    },
    isProAlgorithm() {
      return this.selectedAlgorithm === CalcTypes.pro
    },
    hasProAlgorithm() {
      return this.sector.system?.calcTypes?.map((type) => type.toLowerCase())?.includes(CalcTypes.pro)
    },
    canShowSwitcher() {
      return (this.hasProAlgorithm || this.isProAlgorithm) && !this.sector.constructionType.isCombinedLocation
    }
  },
  methods: {
    ...mapActions({
      getConstructionBySystemId: 'getConstructionBySystemId',
      getRequiredIndexes: 'getRequiredIndexes'
    }),
    selectSystem(evt) {
      this.updateSector('system', null, evt)
      this.setAlgorithm(this.hasProAlgorithm && !this.sector.constructionType.isCombinedLocation
        ? CalcTypes.pro : CalcTypes.lite)
    },
    setAlgorithm(evt) {
      this.updateSector('algorithm', null, evt)
    },
    setScrollHeight() {
      if (this.systems?.length > initialVisibleItems) {
        this.containerHeight = setContainerHeight()
      }
    },
    modalEvent(evt) {
      this.$store.commit('UPDATE_MODAL', { property: 'selectedSystem', value: evt })
    },
    updateSector(property, subproperty, value) {
      this.$store.commit(
        'UPDATE_SECTOR',
        { index: this.sectorIndex, property, subproperty, value }
      )
    },
    reset() {
      this.getConstructionBySystemId(this.sectorIndex)
      this.getRequiredIndexes(this.sectorIndex)
      this.$emit('checkStepValidation')
    }
  },
  mounted() {
    this.setScrollHeight()
    this.currentSystemId = this.id
  }
}
</script>

<style lang="sass">
.system-filter
  overflow: hidden
  overflow-y: auto
  padding-right: rem(5)
  padding-bottom: rem(2)
  &__wrapper
    display: grid
    grid-gap: rem(20)
    width: 100%
    grid-template-rows: auto
    +media((grid-template-columns: (0: 1fr, 580: repeat(2, 1fr), 1070: repeat(3, 1fr))))
    .media-cart
      &:hover
        .media-cart__button--details
          display: flex!important
</style>
